import "./App.css";
import "./Navigation.css";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo from "./images/logo.png";

function Navigation() {
  const navigate = useNavigate();

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      variant="light"
      sticky="top"
      className="narbarOverride"
    >
      <Container>
        <Navbar.Brand className="brand">
          <div
            className="companyTitle"
            onClick={() => {
              navigate("/");
            }}
          >
            <img src={logo} alt="logo" className="logo" />
            InspectMind
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link
              href="/#why"
              onClick={(e) => {
                navigate("/#why");
              }}
              className="navLink"
            >
              Why us
            </Nav.Link>
            <Nav.Link
              href="/#howitworks"
              onClick={(e) => {
                navigate("/#howitworks");
              }}
              className="navLink"
            >
              How it works
            </Nav.Link>
            <Nav.Link
              href="/#userFeeback"
              onClick={(e) => {
                navigate("/#userFeeback");
              }}
              className="navLink"
            >
              ❤️ Engineers love us
            </Nav.Link>
            <Nav.Link
              href="/#contact"
              onClick={(e) => {
                navigate("/#contact");
              }}
              className="navLink"
            >
              Support & Contact
            </Nav.Link>
          </Nav>
          <Button
            variant="dark"
            className="consultation"
            onClick={(e) => {
              window.open(
                "https://promo.inspectmind.ai/general-inspectmind-website-page",
                "_blank"
              );
            }}
          >
            Request a demo
          </Button>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navigation;
